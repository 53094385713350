<template>
    <div class="">
    <div class="row mb-3 mt-0" style="border-color: white;">
        <div class="col-2 col-md-1 text-center pt-3">
            <slot name="icon">
            <span :class="iconClass" class="page-detail-header-icon"></span>
            </slot>
        </div>
        <div class="col-8 col-md-8 pt-3">
            <slot name="main">
            <h2>{{ mainText }}</h2>
            <h3 v-if="subText">{{ subText }}</h3>
            <h4 v-if="subText2">{{ subText2 }}</h4>
            <h4 v-if="subText3">{{ subText3 }}</h4>
            <h4 v-if="subText4">{{ subText4 }}</h4>
            </slot>
        </div>
        <div class="col-2 col-md-3 text-end">
            <slot name="buttons">
            </slot>
        </div>
    </div>
    <div class="row" v-if="$slots.additional">
        <div class="col-12 ">
        <slot name="additional"></slot>
        </div>
    </div>
    </div>
</template>

<script>
    export default {
        name: "PageHeader",

        props: {
            iconClass: String,
            mainText: String,
            subText: String,
            subText2: String,
            subText3: String,
            subText4: String,
        }
    }
</script>

<style scoped>

</style>
