<template>
    <page :title="title" :subtitle="subtitle">
        <div class="row">
            <app-busy v-if="isBusy"></app-busy>
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <slot name="header"></slot>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <b-nav class="nav-tabs">
                            <b-nav-item v-show="!tab.hidden" v-for="(tab, index) in tabs" :key="tab.slotName" :active="activeTabIndex === index" @click="onClickTab($event, index)">{{tab.title}}</b-nav-item>
                        </b-nav>
                        <div class="tab-content panel">
                            <div v-for="(tab, index) in tabs" v-if="activeTabIndex === index" class="p-3" :key="tab.slotName">
                                <slot :name="tab.slotName" >

                                </slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </page>
</template>

<script>
import page from "@/pages/Common/Page";
export default {
    name: 'Details',
    components: {
        page
    },
    props: {
        title: String,
        subtitle: String,
        isBusy: Boolean,
        tabs: Array,
        value: Number,
    },
    data() {
        return {
            activeTabIndex: 0
        }
    },
    methods: {
        onClickTab(event, index) {
            console.log('test on click tab');
            if (this.tabs[index].onBeforeChangeTab) {
                var that = this
                this.tabs[index].onBeforeChangeTab(event).then((result) => {
                    if (result) that.activeTabIndex = index;
                });
            } else {
                this.activeTabIndex = index;
            }
        },

    },
    mounted() {
        if (this.$route.query.tab) {
            this.activeTabIndex = this.tabs.findIndex( (item) => item.slotName === this.$route.query.tab);
            if (this.activeTabIndex < 0) {
                this.activeTabIndex = 0
            }
        }
    }
}
</script>


<style>

</style>
